@import "~@coreui/coreui/scss/coreui.scss";

.bg-accuprepare, .btn-accuprepare {
    background: #50b167;
    color: white;
    transition: background 200ms;
}

.btn-accuprepare:hover {
    background: #479c5b;
    color: white;
    transition: background 200ms;
}

.btn-accuprepare:active {
    background: #3f8b51;
    color: white;
    transition: background 200ms;
}

.input-group-prepend select {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

input.normal-readonly-input[readonly] {
    background: #fff;
}

.coi-exam-logo {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: solid 1px #62d37d;
}

.coi-exam-block {
    border-radius: 3px;
    margin: 5px;
    background: #fff;
    box-shadow: 0px 0px 3px 0px #00000033;
    cursor: pointer;
    padding: 10px;
    transition: all 300ms;
    height: 110px;
    max-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coi-exam-block:hover {
    outline: solid 2px #62d37d;
    background: #62d37d22;
    transition: all 100ms;
}

.coi-exam-block.selected-coi {
    outline: solid 3px #62d37d;
    background: #62d37d22;
    transition: all 200ms;
}

.remove-coi-cross-button {
    position: absolute;
    right: 5px;
    top: -1px;
    font-size: 15px;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    padding: 0px;
    padding-top: -1px;
    text-align: center;
    border-radius: 100%;
    height: 25px;
    width: 25px;
}

.remove-coi-cross-button:hover {
    background: #62d37d44;
}

a, .nav-link, input, button, header a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    outline: none;
    transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
}


@media (min-width: 576px) {
    .modal .modal-dialog {
        max-width: 95vw;
        margin: 1.75rem auto;
    }
}


@media (min-width: 768px) {
    .container, .container-sm, .container-md {
        max-width: 92vw;
    }
}